import { forwardRef, InputHTMLAttributes, useMemo } from "react";
import InputText from "../InputText";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  options: Array<{ value: string; text?: string }>;
  enforceMatch?: boolean;
  invalid?: boolean;
};

const SelectByInputText = forwardRef<HTMLInputElement, Props>(
  ({ options, onBlur, enforceMatch = true, invalid = false, ...rest }, ref) => {
    const randomId = useMemo(() => Math.random().toString(16).substring(2), []);

    return (
      <>
        <InputText
          {...rest}
          list={randomId}
          onBlur={(event) => {
            const element = event.currentTarget;
            if (
              enforceMatch &&
              !options.map((option) => option.value).includes(element.value)
            )
              element.value = "";

            onBlur?.(event);
          }}
          ref={ref}
          invalid={invalid}
          placeholder=""
        />
        <datalist id={randomId}>
          {options.map(({ value, text }, index) => (
            <option key={index} value={value}>
              {text}
            </option>
          ))}
        </datalist>
      </>
    );
  }
);
export default SelectByInputText;
